import { AxiosError } from "axios";

import api from "./apiClient";
import { SearchPaginationQuery, downloadResponseAsJSON } from ".";
import { Carrier, ListResponse, Store, TourOrder, TourOrderDriverType, Trailer, User, ValueListItem } from "./types";

export type TourOrderIdPayload = TourOrder["id"];
export type TourOrdersListPayload = SearchPaginationQuery & {
    from?: string;
    to?: string;
    status?: TourOrder["status"];
    store?: Store["id"];
    carrier?: Carrier["id"];
    driver?: User["id"];
    activity?: ValueListItem["id"];
    withoutTrailer?: boolean;
    fresh?: boolean;
};

export enum AssignTrailerType {
    registered = "registered",
    temporary = "temporary",
}
export interface AssignTrailerPayload {
    tourOrderId: TourOrder["id"];
    type: AssignTrailerType;
    trailer?: Trailer["id"];
    registration?: Trailer["registration"];
    location?: ValueListItem["id"];
}
export interface ChangeTruckPayload {
    tourOrderId: TourOrder["id"];
    truckRegistration: TourOrder["truckRegistration"];
}
export interface AddCommentPayload {
    tourOrderId: TourOrder["id"];
    operationComment?: string | null;
    shippingServiceComment?: string | null;
}
export interface AssignYardDriverPayload {
    tourOrderId: TourOrder["id"];
    taskId: string;
    yardDriver: User["id"] | null;
}

export interface AssignDriverPayload {
    tourOrderId: TourOrder["id"];
    userId: User["id"];
    driverType: TourOrderDriverType;
}

export type TourOrdersListResponse = ListResponse<TourOrder>;
export type TourOrderDetailResponse = TourOrder;
export type AssignTrailerResponse = TourOrder;

export type AssignTrailerError = AxiosError<
    | {
          fields: {
              registration: Trailer["registration"];
          };
      }
    | undefined
>;

// Routes
export const list = async (payload?: TourOrdersListPayload) => {
    return await api.get<TourOrdersListResponse>(`/tourOrders`, { params: payload }).then((response) => response?.data);
};

export const details = async (id?: TourOrderIdPayload) => {
    return await api.get<TourOrderDetailResponse>(`/tourOrders/${id}`).then((response) => response?.data);
};

export const assignTrailer = async ({ tourOrderId, ...payload }: AssignTrailerPayload) => {
    return await api
        .post<AssignTrailerResponse>(`/tourOrders/${tourOrderId}/trailer`, payload)
        .then((response) => response?.data);
};

export const changeTruck = async ({ tourOrderId, ...payload }: ChangeTruckPayload) => {
    return await api.post<TourOrder>(`/tourOrders/${tourOrderId}/truck`, payload).then((response) => response?.data);
};

export const addComment = async ({ tourOrderId, ...payload }: AddCommentPayload) => {
    return await api.post<TourOrder>(`/tourOrders/${tourOrderId}/comment`, payload).then((response) => response?.data);
};

export const cancelTourOrder = async (tourOrderId: TourOrderIdPayload) => {
    return await api.post<TourOrder>(`/tourOrders/${tourOrderId}/cancel`).then((response) => response?.data);
};

export const assignYardDriver = async ({ tourOrderId, taskId, ...payload }: AssignYardDriverPayload) => {
    return await api.post(`/tourOrders/${tourOrderId}/tasks/${taskId}`, payload).then((response) => response?.data);
};

export const assignDriver = async ({ tourOrderId, ...payload }: AssignDriverPayload) => {
    return await api.post(`/tourOrders/${tourOrderId}/assignDriver`, payload).then((response) => response?.data);
};

// Super admin routes
export const updateDateOfTourOrder = async (tourOrderId: TourOrderIdPayload) => {
    return await api.post(`/tourOrders/${tourOrderId}/updateDateOfTourOrder`).then((response) => response.data);
};

export const downloadTourOrderBody = async (tourOrderId: TourOrderIdPayload) => {
    return await api
        .get<Blob>(`/tourOrders/${tourOrderId}/downloadBody`, {
            responseType: "blob",
        })
        .then((response) => {
            downloadResponseAsJSON(response);
            return null;
        });
};

export const deleteTourOrder = async (tourOrderId: TourOrderIdPayload) => {
    return await api.delete(`/tourOrders/${tourOrderId}`).then((response) => response.data);
};
